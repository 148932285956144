<template>
  <div>
    <stats-calc
      v-model="computedCalc"
      :target-doc-ty="targetDocTy"
    />
    <stats-period
      class="mt-8"
      v-model="computedPeriod"
      :target-doc-ty="targetDocTy"
    />
  </div>
</template>

<script>
export default {
  components: {
    StatsCalc: () => import('./stats-calc.vue'),
    StatsPeriod: () => import('./stats-period.vue')
  },
  computed: {
    computedCalc: {
      get () { return this.dataCalc },
      set (v) {
        this.dataCalc = v
        this.dataDocumentEmit()
      }
    },
    computedPeriod: {
      get () { return this.dataPeriod },
      set (v) {
        this.dataPeriod = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = { calc: this.dataCalc, period: this.dataPeriod }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataCalc: null,
      dataPeriod: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    document: Object,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        const stats = this.lodash.cloneDeep(v)
        this.dataCalc = stats?.calc
        this.dataPeriod = stats?.period
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
